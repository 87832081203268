import React from "react"

const Test3 = () => {
  return (
    <div className="p-4">
      <div class="flex flex-row justify-between m-auto bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 p-6 gap-8 rounded-lg ">
        <div class="my-auto">
          <div class="text-lg text-purple-300">Full Mock</div>
          <div class="text-4xl text-purple-100">622k</div>
        </div>
        <div class="text-purple-300 my-auto bg-gradient-to-l from-purple-700 via-purple-800 to-purple-900 rounded-full p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div class="min-h-screen bg-gradient-to-tr from-red-300 to-yellow-200 flex justify-center items-center py-20">
        <div class="md:px-4 md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 space-y-4 md:space-y-0">
          <div class="max-w-sm bg-white px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-500">
            <h3 class="mb-3 text-xl font-bold text-indigo-600">
              Beginner Friendly
            </h3>
            <div class="relative">
              <img
                class="w-full rounded-xl"
                src="https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                alt="Colors"
              />
              <p class="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
                FREE
              </p>
            </div>
            <h1 class="mt-4 text-gray-800 text-3xl font-bold cursor-pointer">
              Javascript Bootcamp for Absolute Beginners
            </h1>
            <div class="my-4">
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <p>1:34:23 Minutes</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <p>3 Parts</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                </span>
                <p>Vanilla JS</p>
              </div>
              <button class="mt-4 text-xl w-full text-white bg-indigo-600 py-1.5 rounded-xl shadow-lg">
                Buy Lesson
              </button>
            </div>
          </div>
          <div class="max-w-sm bg-white px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-500">
            <h3 class="mb-3 text-xl font-bold text-indigo-600">Intermediate</h3>
            <div class="relative">
              <img
                class="w-full rounded-xl"
                src="https://images.unsplash.com/photo-1550684848-fac1c5b4e853?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80"
                alt="Colors"
              />
              <p class="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
                $12
              </p>
              <p class="absolute top-0 right-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg">
                %20 Discount
              </p>
            </div>
            <h1 class="mt-4 text-gray-800 text-3xl font-bold cursor-pointer">
              Write a Gatsby plugin using Typescript
            </h1>
            <div class="my-4">
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <p>1:34:23 Minutes</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <p>3 Parts</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                </span>
                <p>TypeScript</p>
              </div>
              <button class="mt-4 text-xl w-full text-white bg-indigo-600 py-1.5 rounded-xl shadow-lg">
                Start Watching Now
              </button>
            </div>
          </div>
          <div class="max-w-sm bg-white px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-500">
            <h3 class="mb-3 text-xl font-bold text-indigo-600">
              Beginner Friendly
            </h3>
            <div class="relative">
              <img
                class="w-full rounded-xl"
                src="https://images.unsplash.com/photo-1561835491-ed2567d96913?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80"
                alt="Colors"
              />
              <p class="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
                $50
              </p>
            </div>
            <h1 class="mt-4 text-gray-800 text-3xl font-bold cursor-pointer">
              Advanced React Native for Sustainability
            </h1>
            <div class="my-4">
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <p>1:34:23 Minutes</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <p>3 Parts</p>
              </div>
              <div class="flex space-x-1 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600 mb-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                </span>
                <p>Vanilla JS</p>
              </div>
              <button class="mt-4 text-xl w-full text-white bg-indigo-600 py-1.5 rounded-xl shadow-lg">
                Buy Lesson
              </button>
            </div>
          </div>
        </div>
      </div>
      <FeatureSection />
      <Features />
      <TestCard />
      <Name />
      <ModernCard />
      <SimpleCard />
      <BlurredBgCard />
      <StylishCard />
      <ModernAppCard />
      <ProductCard />
    </div>
  )
}

function Features() {
  return (
    <section class="relative pt-16 bg-blueGray-50">
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-500">
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=700&amp;q=80"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-pink-500 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Great for your awesome project
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  Putting together a page has never been easier than matching
                  together pre-made components. From landing pages presentation
                  to login areas, you can easily customise and built your pages.
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-sitemap"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">CSS Components</h6>
                    <p class="mb-4 text-blueGray-500">
                      Notus JS comes with a huge number of Fully Coded CSS
                      components.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      JavaScript Components
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      We also feature many dynamic components for React, NextJS,
                      Vue and Angular.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col min-w-0 mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-newspaper"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">Pages</h6>
                    <p class="mb-4 text-blueGray-500">
                      This extension also comes with 3 sample pages. They are
                      fully coded so you can start working instantly.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-file-alt"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">Documentation</h6>
                    <p class="mb-4 text-blueGray-500">
                      Built by developers for developers. You will love how easy
                      is to to work with Notus JS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-2">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center md:justify-between justify-center">
            <div class="w-full md:w-6/12 px-4 mx-auto text-center">
              <div class="text-sm text-blueGray-500 font-semibold py-1">
                Made with{" "}
                <a
                  href="https://www.creative-tim.com/product/notus-js"
                  class="text-blueGray-500 hover:text-gray-800"
                  target="_blank"
                >
                  Notus JS
                </a>{" "}
                by{" "}
                <a
                  href="https://www.creative-tim.com"
                  class="text-blueGray-500 hover:text-blueGray-800"
                  target="_blank"
                >
                  {" "}
                  Creative Tim
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  )
}

function TestCard() {
  return (
    <section class=" p-6 space-y-6 duration-300 transform  bg-gray-100 h-96 w-80 rounded-2xl rotate-3 hover:rotate-0 top-1/2 left-1/2">
      <div class="flex justify-end">
        <div class="w-4 h-4 bg-gray-900 rounded-full"></div>
      </div>

      <header class="text-xl font-extrabold text-center text-gray-600">
        2021.09.01
      </header>

      <div>
        <p class="text-5xl font-extrabold text-center text-gray-900">
          Online Test <br />
          (Physics)
        </p>
        <p
          class="text-4xl font-extrabold text-center"
          style={{ color: "#FE5401" }}
        >
          2 hours
        </p>
      </div>

      <footer class="flex justify-center">
        <button
          style={{ backgroundColor: "#FF7308" }}
          class="flex items-center px-4 py-3 text-xl font-bold text-white rounded-xl"
        >
          <p>Start</p>
          <svg
            class="w-9 h-9"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </footer>
    </section>
  )
}

function Name(params) {
  return (
    <div class="relative max-w-sm min-w-[340px] bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer">
      <div class="overflow-x-hidden rounded-2xl relative">
        <img
          class="h-40 rounded-2xl w-full object-cover"
          src="https://pixahive.com/wp-content/uploads/2020/10/Gym-shoes-153180-pixahive.jpg"
        />
        <p class="absolute right-2 top-2 bg-white rounded-full p-2 cursor-pointer group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 group-hover:opacity-50 opacity-70"
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </p>
      </div>
      <div class="mt-4 pl-2 mb-2 flex justify-between ">
        <div>
          <p class="text-lg font-semibold text-gray-900 mb-0">Product Name</p>
          <p class="text-md text-gray-800 mt-0">$340</p>
        </div>
        <div class="flex flex-col-reverse mb-1 mr-4 group cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 group-hover:opacity-70"
            fill="none"
            viewBox="0 0 24 24"
            stroke="gray"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

function ModernCard() {
  return (
    <header>
      <nav class="py-8 bg-white">
        <div class="md:container px-4 md:px-0 mx-auto flex justify-between">
          <img
            class="w-16"
            src="https://capp.nicepage.com/bf483e57c95e271afd13aa25e2ecbbe017068d1d/images/default-logo.png"
            alt=""
          />
          <div class="space-y-1.5 cursor-pointer">
            <div class="h-1 w-8 bg-gray-600 rounded"></div>
            <div class="h-1 w-8 bg-gray-600 rounded"></div>
            <div class="h-1 w-8 bg-gray-600 rounded"></div>
          </div>
        </div>
      </nav>

      <div class="bg-green-100 py-14">
        <h3 class="text-2xl tracking-widest text-green-600 text-center">
          FEATURES
        </h3>
        <h1 class="mt-8 text-center text-5xl text-green-600 font-bold">
          Our Features & Services.
        </h1>

        <div class="md:flex md:justify-center md:space-x-8 md:px-14">
          <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
            <div class="w-sm">
              <img
                class="w-64"
                src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg"
                alt=""
              />
              <div class="mt-4 text-green-600 text-center">
                <h1 class="text-xl font-bold">Communications</h1>
                <p class="mt-4 text-gray-600">
                  Pretium lectus quam id leo in vitae turpis. Mattis
                  pellentesque id nibh tortor id.
                </p>
                <button class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">
                  MORE
                </button>
              </div>
            </div>
          </div>

          <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
            <div class="w-sm">
              <img
                class="w-64"
                src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/3b242447f922540fbe750cab/fdf.jpg"
                alt=""
              />
              <div class="mt-4 text-green-600 text-center">
                <h1 class="text-xl font-bold">Inspired Design</h1>
                <p class="mt-4 text-gray-600">
                  Nunc consequat interdum varius sit amet mattis vulputate enim
                  nulla. Risus feugiat.
                </p>
                <button class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">
                  MORE
                </button>
              </div>
            </div>
          </div>

          <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
            <div class="w-sm">
              <img
                class="w-64"
                src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/8cc47b39e719570b996d9879/dsds.jpg"
                alt=""
              />
              <div class="mt-4 text-green-600 text-center">
                <h1 class="text-xl font-bold">Happy Customers</h1>
                <p class="mt-4 text-gray-600">
                  Nisl purus in mollis nunc sed id semper. Rhoncus aenean vel
                  elit scelerisque mauris.
                </p>
                <button class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">
                  MORE
                </button>
              </div>
            </div>
          </div>
        </div>
        <h4 class="text-center font-thin text-xl mt-14">
          Image from{" "}
          <span class="underline text-gray-600 cursor-pointer">Freepik</span>
        </h4>
      </div>
    </header>
  )
}

function SimpleCard(params) {
  return (
    <div class="min-h-screen bg-gray-100 grid items-center justify-center">
      <div class="p-6 bg-white flex items-center space-x-6 rounded-lg shadow-md hover:scale-105 transition transform duration-500 cursor-pointer">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-20 w-20 text-indigo-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </div>
        <div>
          <h1 class="text-xl font-bold text-gray-700 mb-2">Job Security</h1>
          <p class="text-gray-600 w-80 text-sm">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta
            cupiditate ut laboriosam!
          </p>
        </div>
      </div>
    </div>
  )
}

function BlurredBgCard(params) {
  return (
    <div class="bg-gray-50 min-h-screen flex items-center justify-center px-16">
      <div class="relative w-full max-w-lg">
        <div class="absolute top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob "></div>
        <div class="absolute top-0 -right-4 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
        <div class="absolute -bottom-32 left-20 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
        <div class="m-8 relative space-y-4">
          <div class="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
            <div class="flex-1 flex justify-between items-center">
              <div class="h-4 w-48 bg-gray-300 rounded"></div>
              <div class="w-24 h-6 rounded-lg bg-purple-300"></div>
            </div>
          </div>
          <div class="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
            <div class="flex-1 flex justify-between items-center">
              <div class="h-4 w-48 bg-gray-300 rounded"></div>
              <div class="w-24 h-6 rounded-lg bg-purple-300"></div>
            </div>
          </div>
          <div class="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
            <div class="flex-1 flex justify-between items-center">
              <div class="h-4 w-48 bg-gray-300 rounded"></div>
              <div class="w-24 h-6 rounded-lg bg-purple-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function StylishCard(params) {
  return (
    <div style={{ backgroundColor: "#fff8f4" }} class="h-full">
      <div class="container mx-auto  lg:px-20">
        <div class="grid grid-cols-3 h-full pb-40">
          <div class="border-r border-gray-300 mx-3 lg:pl-20">
            <div class=" py-10 pb-3 mt-72 h-4/6 relative bg-purple-100 group hover:bg-purple-200 cursor-pointer transition ease-out duration-300">
              <div>
                <div class="w-4 h-1/5 bg-red-50	absolute right-0 -top-48 bg-purple-100 group-hover:bg-purple-50"></div>
                <img
                  src="https://i.ibb.co/FzkhpcD/pngegg.png"
                  alt="https://www.pngegg.com/en/png-nllal/download"
                />
              </div>
              <div class="px-7 mt-20">
                <h1 class="text-3xl font-bold group-hover:text-purple-300 transition ease-out duration-300">
                  01.
                </h1>
                <h2 class="text-1xl mt-4 font-bold">Roof light lamp</h2>
                <p class="mt-2 opacity-60 group-hover:opacity-70 ">
                  Diverse collection of roof lights of quality
                </p>
              </div>
            </div>
          </div>
          <div class="border-r border-gray-300 mx-3 lg:pl-20">
            <div class=" py-10  pb-3 mt-32 h-4/6 relative bg-indigo-100 group hover:bg-indigo-200 cursor-pointer transition ease-out duration-300">
              <div>
                <div class="w-4 h-1/5 bg-red-50	absolute right-0 -top-48 bg-indigo-100  group-hover:bg-indigo-50"></div>
                <img
                  src="https://i.ibb.co/JB4GWMJ/pngegg-1.png"
                  alt="https://www.pngegg.com/en/png-zquqj/download"
                />
              </div>
              <div class="px-7 mt-20">
                <h1 class="text-3xl font-bold group-hover:text-indigo-300 transition ease-out duration-300">
                  02.
                </h1>
                <h2 class="text-1xl mt-4 font-bold">Lounge Chair</h2>
                <p class="mt-2 opacity-60 group-hover:opacity-70 ">
                  Comfortable collection of perfect lounge chairs
                </p>
              </div>
            </div>
          </div>
          <div class="border-r border-gray-300 mx-3 lg:pl-20">
            <div class=" py-10 pb-3 mt-5 h-4/6 relative bg-red-100 group hover:bg-red-200 cursor-pointer transition ease-out duration-300">
              <div>
                <div class="w-4 h-1/5 bg-red-50	absolute right-0 -bottom-44 bg-red-100 group-hover:bg-red-50"></div>
                <img
                  src="https://i.ibb.co/MgnH44p/pngegg-2.png"
                  alt="https://www.pngegg.com/en/png-epwii/download"
                />
              </div>
              <div class="px-7 mt-5">
                <h1 class="text-3xl font-bold group-hover:text-red-300 transition ease-out duration-300">
                  03.
                </h1>
                <h2 class="text-1xl mt-4 font-bold">Scandinavia Couch</h2>
                <p class="mt-2 opacity-60 group-hover:opacity-70 ">
                  Best selection of scandinavia couch for your home
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ModernAppCard(params) {
  return (
    <div class="relative bg-white py-6 px-6 rounded-3xl w-64 my-4 shadow-xl">
      <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-pink-500 left-4 -top-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
          />
        </svg>
      </div>
      <div class="mt-8">
        <p class="text-xl font-semibold my-2">App Development</p>
        <div class="flex space-x-2 text-gray-400 text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <p>Marketing Team</p>
        </div>
        <div class="flex space-x-2 text-gray-400 text-sm my-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <p>1 Weeks Left</p>
        </div>
        <div class="border-t-2"></div>

        <div class="flex justify-between">
          <div class="my-2">
            <p class="font-semibold text-base mb-2">Team Member</p>
            <div class="flex space-x-2">
              <img
                src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                class="w-6 h-6 rounded-full"
              />
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Woman_7.jpg"
                class="w-6 h-6 rounded-full"
              />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxSqK0tVELGWDYAiUY1oRrfnGJCKSKv95OGUtm9eKG9HQLn769YDujQi1QFat32xl-BiY&usqp=CAU"
                class="w-6 h-6 rounded-full"
              />
            </div>
          </div>
          <div class="my-2">
            <p class="font-semibold text-base mb-2">Progress</p>
            <div class="text-base text-gray-400 font-semibold">
              <p>34%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProductCard(params) {
  return (
    <>
      <div class=" bg-black opacity-80 inset-0 z-0"></div>
      <div class="relative min-h-screen flex flex-col items-center justify-center ">
        <div class="grid mt-8  gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
          <div class="flex flex-col">
            <div class="bg-white shadow-md  rounded-3xl p-4">
              <div class="flex-none lg:flex">
                <div class=" h-full w-full lg:h-48 lg:w-48   lg:mb-0 mb-3">
                  <img
                    src="https://images.unsplash.com/photo-1622180203374-9524a54b734d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
                    alt="Just a flower"
                    class=" w-full  object-scale-down lg:object-cover  lg:h-48 rounded-2xl"
                  />
                </div>
                <div class="flex-auto ml-3 justify-evenly py-2">
                  <div class="flex flex-wrap ">
                    <div class="w-full flex-none text-xs text-blue-700 font-medium ">
                      Shop
                    </div>
                    <h2 class="flex-auto text-lg font-medium">
                      Massive Dynamic
                    </h2>
                  </div>
                  <p class="mt-3"></p>
                  <div class="flex py-4  text-sm text-gray-500">
                    <div class="flex-1 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 mr-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        ></path>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                      </svg>
                      <p class="">Cochin,KL</p>
                    </div>
                    <div class="flex-1 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 mr-2 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <p class="">05-25-2021</p>
                    </div>
                  </div>
                  <div class="flex p-4 pb-2 border-t border-gray-200 "></div>
                  <div class="flex space-x-3 text-sm font-medium">
                    <div class="flex-auto flex space-x-3">
                      <button class="mb-2 md:mb-0 bg-white px-4 py-2 shadow-sm tracking-wider border text-gray-600 rounded-full hover:bg-gray-100 inline-flex items-center space-x-2 ">
                        <span class="text-green-400 hover:text-green-500 rounded-lg">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="shopify"
                            class="svg-inline--fa fa-shopify  w-5 h-5  "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M388.32,104.1a4.66,4.66,0,0,0-4.4-4c-2,0-37.23-.8-37.23-.8s-21.61-20.82-29.62-28.83V503.2L442.76,472S388.72,106.5,388.32,104.1ZM288.65,70.47a116.67,116.67,0,0,0-7.21-17.61C271,32.85,255.42,22,237,22a15,15,0,0,0-4,.4c-.4-.8-1.2-1.2-1.6-2C223.4,11.63,213,7.63,200.58,8c-24,.8-48,18-67.25,48.83-13.61,21.62-24,48.84-26.82,70.06-27.62,8.4-46.83,14.41-47.23,14.81-14,4.4-14.41,4.8-16,18-1.2,10-38,291.82-38,291.82L307.86,504V65.67a41.66,41.66,0,0,0-4.4.4S297.86,67.67,288.65,70.47ZM233.41,87.69c-16,4.8-33.63,10.4-50.84,15.61,4.8-18.82,14.41-37.63,25.62-50,4.4-4.4,10.41-9.61,17.21-12.81C232.21,54.86,233.81,74.48,233.41,87.69ZM200.58,24.44A27.49,27.49,0,0,1,215,28c-6.4,3.2-12.81,8.41-18.81,14.41-15.21,16.42-26.82,42-31.62,66.45-14.42,4.41-28.83,8.81-42,12.81C131.33,83.28,163.75,25.24,200.58,24.44ZM154.15,244.61c1.6,25.61,69.25,31.22,73.25,91.66,2.8,47.64-25.22,80.06-65.65,82.47-48.83,3.2-75.65-25.62-75.65-25.62l10.4-44s26.82,20.42,48.44,18.82c14-.8,19.22-12.41,18.81-20.42-2-33.62-57.24-31.62-60.84-86.86-3.2-46.44,27.22-93.27,94.47-97.68,26-1.6,39.23,4.81,39.23,4.81L221.4,225.39s-17.21-8-37.63-6.4C154.15,221,153.75,239.8,154.15,244.61ZM249.42,82.88c0-12-1.6-29.22-7.21-43.63,18.42,3.6,27.22,24,31.23,36.43Q262.63,78.68,249.42,82.88Z"
                            ></path>
                          </svg>
                        </span>
                        <span>62 Products</span>
                      </button>
                    </div>
                    <button
                      class="mb-2 md:mb-0 bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800"
                      type="button"
                      aria-label="like"
                    >
                      Edit Shop
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col ">
            <div class="bg-white shadow-md  rounded-3xl p-4">
              <div class="flex-none lg:flex">
                <div class=" h-full w-full lg:h-48 lg:w-48   lg:mb-0 mb-3">
                  <img
                    src="https://images.unsplash.com/photo-1585399000684-d2f72660f092?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1951&amp;q=80"
                    alt="Just a flower"
                    class=" w-full  object-scale-down lg:object-cover  lg:h-48 rounded-2xl"
                  />
                </div>
                <div class="flex-auto ml-3 justify-evenly py-2">
                  <div class="flex flex-wrap ">
                    <div class="w-full flex-none text-xs text-blue-700 font-medium ">
                      Shop
                    </div>
                    <h2 class="flex-auto text-lg font-medium">
                      Umbrella Corporation
                    </h2>
                  </div>
                  <p class="mt-3"></p>
                  <div class="flex py-4  text-sm text-gray-500">
                    <div class="flex-1 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 mr-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        ></path>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                      </svg>
                      <p class="">Mumbai,MH</p>
                    </div>
                    <div class="flex-1 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 mr-2 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <p class="">05-25-2021</p>
                    </div>
                  </div>
                  <div class="flex p-4 pb-2 border-t border-gray-200 "></div>
                  <div class="flex space-x-3 text-sm font-medium">
                    <div class="flex-auto flex space-x-3">
                      <button class="mb-2 md:mb-0 bg-white px-4 py-2 shadow-sm tracking-wider border text-gray-600 rounded-full hover:bg-gray-100 inline-flex items-center space-x-2 ">
                        <span class="text-green-400 hover:text-green-500 rounded-lg">
                          <img
                            src="https://image.flaticon.com/icons/png/512/168/168810.png"
                            class="svg-inline--fa fa-shopify  w-5 h-5 "
                          />
                        </span>
                        <span>60 Products</span>
                      </button>
                    </div>
                    <button
                      class="mb-2 md:mb-0 bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800"
                      type="button"
                      aria-label="like"
                    >
                      Edit Shop
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function FeatureSection(params) {
  return (
    <div class="py-16">
      <div class="container m-auto px-6">
        <div class="lg:flex justify-between items-center">
          <div class="lg:w-6/12 lg:p-0 p-7">
            <h1 class="text-4xl font-bold leading-tight mb-5 capitalize">
              {" "}
              Professional Tailwind theme designed for developers.{" "}
            </h1>
            <p class="text-xl">
              {" "}
              With Tailwind you can optimized the customization process to save
              your team time when building websites.{" "}
            </p>

            <div class="py-5">
              <a
                href="#"
                class="text-white rounded-full py-2 px-5 text-lg font-semibold bg-purple-600 inline-block border border-purple-600 mr-3"
              >
                Try for free
              </a>
              <a
                href="#"
                class="text-black rounded-full py-2 px-5 text-lg font-semibold bg-gray-400 inline-block border hover:bg-white hover:text-black"
              >
                Requist a demo
              </a>
            </div>
          </div>
          <div class="lg:w-5/12 order-2 transform scale-100 ">
            <img
              src="https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"
              style={{
                transform:
                  "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
              }}
              alt=""
              class="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Test3
